import { template as template_e6d713181365483c9b74ea381af77cef } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { EmptyObject } from '@ember/component/helper';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import UiForm from '@onwardcare/ui-components/components/ui-form';
import set from 'ember-set-helper/helpers/set';
import type { UploadFile } from 'ember-file-upload';
import { restartableTask } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import { vaToolsNamespace } from 'onward-portal/utils/adapter-namespaces';
import UiFilePicker from '../ui-file-picker';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import type AccountModel from 'onward-portal/models/account';
import type UserModel from 'onward-portal/models/user';
import { query } from 'ember-data-resources';
import UiAlert from '@onwardcare/ui-components/components/ui-alert';
import type StorageService from 'onward-portal/services/storage';
export interface ImportManifestSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
interface VetrideManifestFile {
    id: string;
    account_id: number;
    filename: string;
    row_count: number;
    status: string;
}
export const SAVED_CUSTODIAN_KEY = 'va-tools-custodians';
export default class ImportManifestComponent extends Component<ImportManifestSignature> {
    @service
    router: RouterService;
    @service
    storage: StorageService;
    @service
    store: Store;
    @tracked
    errorMessage: string | null = null;
    @tracked
    errorFile: VetrideManifestFile | null = null;
    @tracked
    selectedAccount: AccountModel | null = null;
    @tracked
    users: UserModel[] = [];
    @tracked
    selectedUser: UserModel | null = null;
    @tracked
    selectedFile: UploadFile | null = null;
    vaAccounts = query<AccountModel>(this, 'account', ()=>{
        return {
            filter: {
                parent_account_name: 'VA'
            }
        };
    });
    fetchUsers = restartableTask(async (accountId: string)=>{
        const users = await this.store.query('user', {
            account_id: accountId
        });
        this.users = users.slice();
    });
    @action
    async accountSelected(account: AccountModel) {
        this.selectedAccount = account;
        this.selectedUser = null;
        await this.fetchUsers.perform(account.id);
        const savedCustodian = this.retreiveCustodian(account.id);
        this.selectedUser = this.users.find((user)=>user.id === savedCustodian) ?? null;
    }
    @action
    uploadManifestFile(file: UploadFile) {
        this.selectedFile = file;
    }
    retreiveCustodian(accountId: string) {
        const savedCustodians = this.storage.getItem(SAVED_CUSTODIAN_KEY);
        const custodians = savedCustodians ? JSON.parse(savedCustodians) : {};
        return custodians[accountId];
    }
    storeCustodian(accountId: string, custodianId: string) {
        const savedCustodians = this.storage.getItem(SAVED_CUSTODIAN_KEY);
        const custodians = savedCustodians ? JSON.parse(savedCustodians) : {};
        custodians[accountId] = custodianId;
        this.storage.setItem(SAVED_CUSTODIAN_KEY, JSON.stringify(custodians));
    }
    handleSubmit = restartableTask(async ()=>{
        this.errorMessage = null;
        if (this.selectedAccount === null) {
            this.errorMessage = 'You must select an account.';
            return;
        }
        if (this.selectedUser === null) {
            this.errorMessage = 'You must select a custodian.';
            return;
        }
        if (this.selectedFile === null) {
            this.errorMessage = 'You must select a manifest file.';
            return;
        }
        this.storeCustodian(this.selectedAccount.id, this.selectedUser.id);
        try {
            const adapter = this.store.adapterFor('application');
            let headers = adapter.headers;
            // Rails doesn't like when you send a content type with a multipart form.
            delete headers['Content-Type'];
            const response = await this.selectedFile.upload(`${adapter.host}/${vaToolsNamespace}/vetride_manifest_files/upload`, {
                headers,
                data: {
                    account_id: this.selectedAccount.id,
                    user_id: this.selectedUser.id
                }
            });
            const json = await response.json();
            if (json.vetride_manifest_file.status === 'failed') {
                this.errorMessage = 'There was an error processing your file. Contact engineering and give them the following:';
                this.errorFile = json.vetride_manifest_file;
            } else {
                this.router.transitionTo('authenticated.va-tools.ride-requests');
            }
        } catch (ex: any) /* eslint-disable-line @typescript-eslint/no-explicit-any */ {
            const error = await ex.json();
            this.errorMessage = error.message ?? 'File upload failed. Please try again.';
        }
    });
    static{
        template_e6d713181365483c9b74ea381af77cef(`
    <div class='flex max-w-sm flex-col gap-4'>
      {{#if this.errorMessage}}
        <UiAlert data-test-id='error-message' @variant='danger'>
          <p>{{this.errorMessage}}</p>

          {{#if this.errorFile}}
            <p class='mt-2'>
              VetRide Manifest File ID:
              <span class='font-semibold'>{{this.errorFile.id}}</span>
            </p>
          {{/if}}
        </UiAlert>
      {{/if}}

      <UiForm @onSubmit={{perform this.handleSubmit}} as |Form|>
        <Form.Group data-test-id='source' as |Group|>
          <Group.Label>VA Account</Group.Label>
          <Group.Listbox
            @value={{this.selectedAccount}}
            @onChange={{this.accountSelected}}
            as |listbox|
          >
            <listbox.Button @placeholder='Select an account...'>
              {{this.selectedAccount.name}}
            </listbox.Button>

            {{#each this.vaAccounts.records as |account|}}
              <listbox.Option @value={{account}}>
                {{account.name}}
              </listbox.Option>
            {{/each}}
          </Group.Listbox>
        </Form.Group>

        <Form.Group data-test-id='custodian' as |Group|>
          <Group.Label>Book Ride as Custodian</Group.Label>
          <Group.Listbox
            @disabled={{if (isBlank this.selectedAccount) true false}}
            @value={{this.selectedUser}}
            @onChange={{set this 'selectedUser'}}
            as |listbox|
          >
            <listbox.Button
              @placeholder={{if
                this.fetchUsers.isRunning
                'Loading...'
                'Select a custodian...'
              }}
            >
              {{this.selectedUser.displayName}}
            </listbox.Button>

            {{#each this.users as |user|}}
              <listbox.Option @value={{user}}>
                {{user.displayName}}
              </listbox.Option>
            {{else}}
              <listbox.Empty>
                There are no custodians for this source.
              </listbox.Empty>
            {{/each}}
          </Group.Listbox>
        </Form.Group>

        <Form.Group data-test-id='manifest-file'>
          <UiFilePicker
            @accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            @fieldName='manifest-file'
            @fileQueueName='manifestFile'
            @label='Choose a manifest file'
            @onFileSelected={{this.uploadManifestFile}}
          />
        </Form.Group>

        <Form.Actions as |Actions|>
          <Actions.Submit>
            Upload
          </Actions.Submit>
        </Form.Actions>
      </UiForm>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'VaTools::ImportManifest': typeof ImportManifestComponent;
    }
}
