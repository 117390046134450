import { template as template_d7bd6183147e491685606cc6a99d72b2 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
export interface UiMenuTriggerSignature {
    Element: HTMLButtonElement;
    Args: {
        // Need to actually make types for the ember-headless-ui menu.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        menu: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        velcroHook: any;
    };
    Blocks: {
        default: [];
    };
}
const UiMenuTriggerComponent: TOC<UiMenuTriggerSignature> = template_d7bd6183147e491685606cc6a99d72b2(`
  <@menu.Button ...attributes {{@velcroHook}}>
    {{yield}}
  </@menu.Button>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UiMenuTriggerComponent;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'ui-menu/trigger': typeof UiMenuTriggerComponent;
        'UiMenu::Trigger': typeof UiMenuTriggerComponent;
    }
}
