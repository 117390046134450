import { template as template_9ac1da253d91403facfeb450e3ac9cd5 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import type UserLanguageModel from '../models/user-language';
const UserLanguage: TOC<{
    Element: HTMLSpanElement;
    Args: {
        userLanguage: UserLanguageModel;
    };
    Blocks: EmptyObject;
}> = template_9ac1da253d91403facfeb450e3ac9cd5(`
  <span ...attributes>
    <span data-test-id='language-name'>
      {{@userLanguage.language.displayName}}
    </span>

    <span data-test-id='language-fluency' class='text-xs italic text-gray-500'>
      {{#if @userLanguage.isPrimary}}
        (primary)
      {{else}}
        ({{@userLanguage.fluency}})
      {{/if}}
    </span>
  </span>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const UserLanguages: TOC<{
    Element: HTMLSpanElement;
    Args: {
        userLanguages: UserLanguageModel[];
    };
    Blocks: EmptyObject;
}> = template_9ac1da253d91403facfeb450e3ac9cd5(`
  <ul data-test-id='driver-languages' class='flex flex-wrap gap-1'>
    {{#each @userLanguages as |userLanguage|}}
      <li class='group flex whitespace-nowrap'>
        <UserLanguage @userLanguage={{userLanguage}} />
        <span class='group-last:hidden'>,</span>
      </li>
    {{/each}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UserLanguages;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        UserLanguages: typeof UserLanguages;
    }
}
