import { template as template_788b9dcfb959479998de011c740fde91 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type RouterService from '@ember/routing/router-service';
import type SessionService from '../../services/onward-session';
import type { EmptyObject } from '@ember/component/helper';
import UiForm from '@onwardcare/ui-components/components/ui-form';
import UiAlert from '@onwardcare/ui-components/components/ui-alert';
export interface SignInFormSignature {
    Element: HTMLFormElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
export default class SignInForm extends Component<SignInFormSignature> {
    @service
    router: RouterService;
    @service('onward-session')
    session: SessionService;
    @tracked
    error: string | null = null;
    @action
    async handleSignIn(event: SubmitEvent) {
        this.error = null;
        if (event.target instanceof HTMLFormElement) {
            const data = new FormData(event.target);
            try {
                await this.session.authenticate('authenticator:onward', data.get('email'), data.get('password'));
            } catch (ex: any) /* eslint-disable-line @typescript-eslint/no-explicit-any */ {
                if (ex.errors && ex.errors[0]) {
                    this.error = ex.errors[0];
                } else {
                    this.error = 'Something went wrong trying to sign in. Please try again later.';
                }
            }
        }
    }
    static{
        template_788b9dcfb959479998de011c740fde91(`
    <UiForm @onSubmit={{this.handleSignIn}} as |Form|>
      {{#if this.error}}
        <UiAlert data-test-id='error-message' @variant='danger' class='mb-4'>
          {{this.error}}
        </UiAlert>
      {{/if}}

      <Form.Group data-test-id='email' as |Group|>
        <Group.Label>Email address</Group.Label>
        <Group.Input name='email' autocomplete='email' />
      </Form.Group>

      <Form.Group data-test-id='password' as |Group|>
        <Group.Label>Password</Group.Label>
        <Group.Input type='password' name='password' autocomplete='password' />
      </Form.Group>

      <Form.Actions as |Actions|>
        <Actions.Submit @full={{true}}>
          Sign in
        </Actions.Submit>
      </Form.Actions>
    </UiForm>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'SignIn::SignInForm': typeof SignInForm;
    }
}
