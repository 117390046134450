import { template as template_dccdd8e9b0524f5e820f519bbbea1891 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import rideTypes from '../../utils/data/ride-types';
import type UiFormGroupComponent from '@onwardcare/ui-components/components/ui-form/group';
export interface RideTypeFilterSignature {
    Element: HTMLDivElement;
    Args: {
        // TODO: Need to figure out how to properly use the `UiForm` block types.
        form: {
            Group: typeof UiFormGroupComponent;
        };
        rideTypeIds: string[];
        onChange: (rideTypeIds: string[]) => unknown;
    };
    Blocks: EmptyObject;
}
export default class RideTypeFilterComponent extends Component<RideTypeFilterSignature> {
    get rideTypeDisplay() {
        const selectedRideTypes = rideTypes.filter((rideType)=>this.args.rideTypeIds.includes(rideType.id));
        return selectedRideTypes.length > 0 ? selectedRideTypes.map((rideType)=>rideType.name).join(', ') : null;
    }
    static{
        template_dccdd8e9b0524f5e820f519bbbea1891(`
    <@form.Group data-test-id='ride-type' as |Group|>
      <Group.Label class='flex items-center gap-1'>
        Ride Type
      </Group.Label>
      <Group.Listbox
        @multiple={{true}}
        @value={{@rideTypeIds}}
        @onChange={{@onChange}}
        as |listbox|
      >
        <listbox.Button @placeholder='All Ride Types'>
          {{this.rideTypeDisplay}}
        </listbox.Button>

        {{#each rideTypes as |rideType|}}
          <listbox.Option @value={{rideType.id}}>
            {{rideType.name}}
          </listbox.Option>
        {{/each}}
      </Group.Listbox>
    </@form.Group>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Filters::RideTypeFilter': typeof RideTypeFilterComponent;
    }
}
