import { template as template_a58b9564731d4e0ea9328938b1135172 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
export interface UiSectionTitleSignature {
    Element: HTMLHeadingElement;
    Args: EmptyObject;
    Blocks: {
        default: [];
    };
}
const UiSectionTitleComponent: TOC<UiSectionTitleSignature> = template_a58b9564731d4e0ea9328938b1135172(`
  <h2 class='text-xl font-semibold text-gray-900 lg:text-2xl'>
    {{yield}}
  </h2>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UiSectionTitleComponent;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        UiSectionTitle: typeof UiSectionTitleComponent;
    }
}
