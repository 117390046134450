import { template as template_d220ffc615da495d8f24b9d081779b7d } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import { action } from '@ember/object';
import { on } from '@ember/modifier';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
import UiInput from '@onwardcare/ui-components/components/ui-input';
export interface UiSearchSignature {
    Element: HTMLDivElement;
    Args: {
        term?: string;
        onClear?: () => void;
    };
    Blocks: EmptyObject;
}
export default class UiSearchComponent extends Component<UiSearchSignature> {
    @action
    handleClear() {
        this.args.onClear?.();
    }
    static{
        template_d220ffc615da495d8f24b9d081779b7d(`
    {{!
      We need the "grow" class to be on here so that consumers can have this
      field grow to fill the available space.
    }}
    <div class='relative grow'>
      <UiInput
        name='search-input'
        data-test-id='search-input'
        placeholder='Search...'
        @value={{@term}}
        ...attributes
      />
      {{#if @term}}
        <button
          type='button'
          data-test-id='search-clear-btn'
          class='absolute bottom-0 right-2 top-0 text-gray-400 hover:text-gray-600 focus:text-gray-600 focus:outline-none'
          {{on 'click' this.handleClear}}
        >
          <span class='sr-only'>Clear search</span>
          <UiIcon @icon='x-circle' @type='micro' />
        </button>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        UiSearch: typeof UiSearchComponent;
    }
}
