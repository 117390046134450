import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { action } from '@ember/object';
import type Transition from '@ember/routing/transition';
import type RouterService from '@ember/routing/router-service';
import type FeatureService from '../services/feature';
import type SessionService from '../services/onward-session';
import type SettingsService from '../services/settings';

export default class Application extends Route {
  @service declare feature: FeatureService;
  @service declare router: RouterService;
  @service('onward-session') declare session: SessionService;
  @service declare settings: SettingsService;

  async beforeModel(transition: Transition) {
    // We need to load the settings first so we know the API URL.
    await this.settings.load();
    await this.session.setup();
    await this.feature.load();

    // If going to the root authenticated route, redirect to the default module.
    if (
      this.session.isAuthenticated &&
      transition.to?.name === 'authenticated.index'
    ) {
      this.router.replaceWith('authenticated.reporting.index');
    }
  }

  @action
  didTransition() {
    // Makes sure that the page gets scrolled to the top when changing routes.
    window.scrollTo({ top: 0, left: 0 });
  }
}
