import { template as template_6ac61ff8717c4ff2a2c521f5f24f7c65 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import BasicColumnChart from '../basic-column-chart';
export interface CompanionVsReferredSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const CompanionVsReferred: TOC<CompanionVsReferredSignature> = template_6ac61ff8717c4ff2a2c521f5f24f7c65(`
  <BasicColumnChart
    @modelName='companion-vs-referred-count'
    @stacking='normal'
    @title='Completed Companion vs. Referred Rides'
    @tooltipText='Count of trips completed or no showed within the date range by high level type of Companion or Referred. Referred includes wheelchair, stretcher, Uber/Lyft, and ambulance.'
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default CompanionVsReferred;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::CompanionVsReferred': typeof CompanionVsReferred;
    }
}
