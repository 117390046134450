import { template as template_bee575c2a0a147958a93a8bb146bd262 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import BasicColumnChart from '../basic-column-chart';
export interface VolumeByTransportPartnerSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const ChartComponent: TOC<VolumeByTransportPartnerSignature> = template_bee575c2a0a147958a93a8bb146bd262(`
  <BasicColumnChart
    @modelName='volume-by-transport-partner'
    @stacking='normal'
    @title='Volume by Transport Partner'
    @tooltipText='Distribution of trips by Transport Partner.'
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ChartComponent;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::VolumeByTransportPartner': typeof ChartComponent;
    }
}
