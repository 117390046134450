import { template as template_a8e602276d154e1a9857639dd7d9ee76 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import { action } from '@ember/object';
import { query } from 'ember-data-resources';
import { eq } from 'ember-truth-helpers';
import UiForm from '@onwardcare/ui-components/components/ui-form';
import UiSearch from '../ui-search';
import type AccountModel from 'onward-portal/models/account';
import rideCompletionStatuses from '../../utils/data/ride-completion-status';
export interface RequestsFilter {
    accountId?: string | null;
    hasChanges?: boolean;
    rideCompletionStatuses?: string[];
    term?: string;
}
export type RequestStatus = 'new' | 'open' | 'closed';
export interface RequestsFilterSignature {
    Element: HTMLDivElement;
    Args: {
        params?: RequestsFilter;
        onFilterChanged?: (params: RequestsFilter) => void;
        requestStatus: RequestStatus;
    };
    Blocks: EmptyObject;
}
export default class RequestsFilterComponent extends Component<RequestsFilterSignature> {
    get selectedAccount() {
        const accountId = this.args.params?.accountId;
        if (accountId) {
            const account = this.vaAccounts.records?.find((account)=>account.id === accountId);
            if (account) {
                return account;
            }
        }
        return null;
    }
    get selectedStatuses() {
        const statuses = this.args.params?.rideCompletionStatuses;
        if (statuses) {
            const rideStatuses = this.rideStatuses.filter((rideStatus)=>this.args.params?.rideCompletionStatuses?.includes(rideStatus.id));
            if (rideStatuses) {
                return rideStatuses.map((status)=>status.id);
            }
        }
        return [];
    }
    get rideStatuses() {
        return rideCompletionStatuses.filter((status)=>status.state === 'historical');
    }
    get rideStatusesDisplay() {
        const rideStatuses = this.rideStatuses.filter((rideStatus)=>this.args.params?.rideCompletionStatuses?.includes(rideStatus.id));
        return rideStatuses ? rideStatuses.map((rideStatus)=>rideStatus.name).join(', ') : null;
    }
    vaAccounts = query<AccountModel>(this, 'account', ()=>{
        return {
            filter: {
                parent_account_name: 'VA'
            }
        };
    });
    @action
    accountIdSelected(account: AccountModel | null) {
        this.args.onFilterChanged?.({
            ...this.args.params,
            accountId: account ? account.id : null
        });
    }
    @action
    rideStatusSelected(statuses: string[]) {
        this.args.onFilterChanged?.({
            ...this.args.params,
            rideCompletionStatuses: statuses
        });
    }
    @action
    hasChangesChanged(checked: boolean) {
        this.args.onFilterChanged?.({
            ...this.args.params,
            hasChanges: checked
        });
    }
    @action
    handleSubmit(event: SubmitEvent) {
        if (event.target instanceof HTMLFormElement) {
            const data = new FormData(event.target);
            const term = data.get('requests-search') as string;
            this.args.onFilterChanged?.({
                ...this.args.params,
                term
            });
        }
    }
    @action
    handleClear() {
        this.args.onFilterChanged?.({
            ...this.args.params,
            term: ''
        });
    }
    static{
        template_a8e602276d154e1a9857639dd7d9ee76(`
    <UiForm
      data-test-id='requests-filter-form'
      class='grid max-w-5xl grid-cols-1 items-end gap-5 sm:grid-cols-3 md:grid-cols-4'
      @noStyles={{true}}
      @onSubmit={{this.handleSubmit}}
      as |Form|
    >
      <Form.Group class='max-w-xs' data-test-id='account-id' as |Group|>
        <Group.Label>VA Account:</Group.Label>
        <Group.Listbox
          @allowClear={{true}}
          @value={{this.selectedAccount}}
          @onChange={{this.accountIdSelected}}
          as |listbox|
        >
          <listbox.Button @placeholder='All VA Accounts'>
            {{this.selectedAccount.name}}
          </listbox.Button>

          {{#each this.vaAccounts.records as |account|}}
            <listbox.Option @value={{account}}>
              {{account.name}}
            </listbox.Option>
          {{/each}}
        </Group.Listbox>
      </Form.Group>

      {{#unless (eq @requestStatus 'new')}}
        <Form.Group class='max-w-xs' data-test-id='ride-status' as |Group|>
          <Group.Label>
            Onward Ride Status
          </Group.Label>
          <Group.Listbox
            @multiple={{true}}
            @value={{this.selectedStatuses}}
            @onChange={{this.rideStatusSelected}}
            as |listbox|
          >
            <listbox.Button @placeholder='All Onward Ride Statuses'>
              {{this.rideStatusesDisplay}}
            </listbox.Button>

            {{#each this.rideStatuses as |rideStatus|}}
              <listbox.Option @value={{rideStatus.id}}>
                {{rideStatus.name}}
              </listbox.Option>
            {{/each}}
          </Group.Listbox>
        </Form.Group>
      {{/unless}}

      {{#if (eq @requestStatus 'open')}}
        <Form.Group class='max-w-xs' data-test-id='has-updates' as |Group|>
          <Group.Checkbox
            class='h-9'
            @checked={{@params.hasChanges}}
            @onChange={{this.hasChangesChanged}}
          >
            Has VetRides Updates
          </Group.Checkbox>
        </Form.Group>
      {{/if}}

      <Form.Group class='max-w-xs' data-test-id='requests-search' as |Group|>
        <Group.Label for='requests-search'>
          Passenger or Trip ID:
        </Group.Label>
        <UiSearch
          placeholder='Search Requests...'
          id='requests-search'
          name='requests-search'
          @term={{@params.term}}
          @onClear={{this.handleClear}}
        />
      </Form.Group>
    </UiForm>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'VaTools::RequestsFilter': typeof RequestsFilterComponent;
    }
}
