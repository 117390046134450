import { template as template_d22178b6fd1f41e2874c08d2903238dc } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { EmptyObject } from '@ember/component/helper';
import { action } from '@ember/object';
import { restartableTask, timeout } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import { notEq } from 'ember-truth-helpers';
import { extractErrorMessage } from 'onward-portal/utils/error-handlers';
import type VectorcareServiceLevel from 'onward-portal/models/vectorcare-service-level';
import UiAlert from '@onwardcare/ui-components/components/ui-alert';
import UiButton from '@onwardcare/ui-components/components/ui-button';
import UiButtonSet from '@onwardcare/ui-components/components/ui-button-set';
import UiForm from '@onwardcare/ui-components/components/ui-form';
import UiModal from '../ui-modal';
export interface ServiceLevelFormSignature {
    Element: HTMLDivElement;
    Args: {
        onCancel: () => unknown;
        onSave: () => unknown;
        serviceLevel: VectorcareServiceLevel | null;
    };
    Blocks: EmptyObject;
}
export default class ServiceLevelFormComponent extends Component<ServiceLevelFormSignature> {
    @tracked
    errorMessage: string | null = null;
    @action
    cancel() {
        this.errorMessage = null;
        this.args.serviceLevel?.rollbackAttributes();
        this.args.onCancel();
    }
    @action
    async closeModal(close: () => unknown) {
        // TODO: Need to figure out how to handle this automatically.
        close();
        // The fade duration for the modal is 300.
        await timeout(350);
    }
    saveServiceLevel = restartableTask(async (close: () => unknown, event: SubmitEvent)=>{
        const level = this.args.serviceLevel;
        if (!level) {
            return;
        }
        if (event.target instanceof HTMLFormElement) {
            const data = new FormData(event.target);
            const name = data.get('level-name') as string;
            const enabled = data.get('level-enabled') as string | null;
            this.errorMessage = null;
            try {
                level.name = name;
                level.enabled = enabled === 'on';
                await level.save();
                await this.closeModal(close);
                this.args.onSave?.();
            } catch (ex) {
                this.errorMessage = extractErrorMessage(ex, 'Something went wrong trying to save the service level.');
            }
        }
    });
    static{
        template_d22178b6fd1f41e2874c08d2903238dc(`
    <UiModal @isOpen={{notEq @serviceLevel null}} @onClose={{this.cancel}}>
      <:title>
        {{if @serviceLevel.isNew 'New Service Level' 'Edit Service Level'}}
      </:title>

      <:body as |Body|>
        <UiForm
          @onSubmit={{perform this.saveServiceLevel Body.close}}
          as |Form|
        >
          {{#if this.errorMessage}}
            <UiAlert data-test-id='error-message' @variant='danger'>
              {{this.errorMessage}}
            </UiAlert>
          {{/if}}

          <Form.Group
            data-test-id='name'
            @model={{@serviceLevel}}
            @property='name'
            as |Group|
          >
            <Group.Label>Name</Group.Label>
            <Group.Input
              name='level-name'
              autocomplete='off'
              @value={{@serviceLevel.name}}
            />
            <Group.ValidationErrors />
          </Form.Group>

          <Form.Group data-test-id='enabled' as |Group|>
            <Group.Checkbox
              @name='level-enabled'
              @checked={{@serviceLevel.enabled}}
            >
              Enabled
            </Group.Checkbox>
          </Form.Group>

          <Form.Actions class='flex justify-end' as |Actions|>
            <UiButtonSet>
              <UiButton data-test-id='cancel-btn' @onClick={{Body.close}}>
                Cancel
              </UiButton>
              <Actions.Submit>
                Save
              </Actions.Submit>
            </UiButtonSet>
          </Form.Actions>
        </UiForm>
      </:body>
    </UiModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Vectorcare::ServiceLevelForm': typeof ServiceLevelFormComponent;
    }
}
