import { template as template_3d547480a7b84348b162f98cd5b07a5a } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import type SessionService from '../../services/onward-session';
import type { EmptyObject } from '@ember/component/helper';
export interface AccountMenuSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
export default class AccountMenuComponent extends Component<AccountMenuSignature> {
    @service('onward-session')
    session: SessionService;
    static{
        template_3d547480a7b84348b162f98cd5b07a5a(`
    <div data-test-id='account-context'>
      <span class='text-sm leading-6 text-gray-900' aria-hidden='true'>
        {{this.session.user.accountName}}
      </span>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'MainLayout::AccountMenu': typeof AccountMenuComponent;
    }
}
