import { template as template_83947009622a415ea0edf9758d83ce08 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
export interface CopyrightYearSignature {
    Args: EmptyObject;
    Blocks: EmptyObject;
}
export default class CopyrightYearComponent extends Component<CopyrightYearSignature> {
    year = new Date().getFullYear();
    static{
        template_83947009622a415ea0edf9758d83ce08(`{{this.year}}`, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        CopyrightYear: typeof CopyrightYearComponent;
    }
}
