import { template as template_3ccbb51830f04c4c9b36b9089ce6f8df } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import { add } from 'date-fns';
import { createDateRangeItem, type DateRangeOption, type FilterDateRange } from '../../utils/filters/date-range';
import DateRangeFilter from './date-range-filter';
import type UiFormGroupComponent from '@onwardcare/ui-components/components/ui-form/group';
export const dateRanges: DateRangeOption[] = [
    createDateRangeItem('today', 'Today', new Date(), new Date()),
    createDateRangeItem('yesterday', 'Yesterday', add(new Date(), {
        days: -1
    }), add(new Date(), {
        days: -1
    })),
    createDateRangeItem('yesterday-before', 'Yesterday and Before', null, add(new Date(), {
        days: -1
    })),
    createDateRangeItem('custom', 'Custom', null, null)
];
const BookTimeFilter: TOC<{
    Element: HTMLDivElement;
    Args: {
        // TODO: Need to figure out how to properly use the `UiForm` block types.
        form: {
            Group: typeof UiFormGroupComponent;
        };
        dateRange: FilterDateRange | null;
        onChange: (dateRange: FilterDateRange) => unknown;
    };
    Blocks: EmptyObject;
}> = template_3ccbb51830f04c4c9b36b9089ce6f8df(`
  <DateRangeFilter
    data-test-id='book-time'
    @form={{@form}}
    @dateRange={{@dateRange}}
    @label='Book Date'
    @options={{dateRanges}}
    @onChange={{@onChange}}
  >
    <:helpText>
      Uses the timezone of account the ride was booked in.
    </:helpText>
  </DateRangeFilter>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BookTimeFilter;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Filters::BookTimeFilter': typeof BookTimeFilter;
    }
}
