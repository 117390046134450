import { template as template_89c7478d8ad74de68e355b5c86e6677c } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type { EmptyObject } from '@ember/component/helper';
import { restartableTask } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import set from 'ember-set-helper/helpers/set';
import type VetrideTripModel from 'onward-portal/models/vetride-trip';
import { extractErrorMessage } from 'onward-portal/utils/error-handlers';
import LinkRideForm from './link-ride-form';
import UiAlert from '@onwardcare/ui-components/components/ui-alert';
import UiButton from '@onwardcare/ui-components/components/ui-button';
import UiButtonSet from '@onwardcare/ui-components/components/ui-button-set';
import UiForm from '@onwardcare/ui-components/components/ui-form';
interface RideRequestFormSignature {
    Element: HTMLDivElement;
    Args: {
        onSaved: () => unknown;
        rideRequest: VetrideTripModel;
    };
    Blocks: EmptyObject;
}
export default class RideRequestFormComponent extends Component<RideRequestFormSignature> {
    @tracked
    errorMessage: string | null = null;
    @tracked
    showRidePrompt = false;
    @tracked
    showLinkRideForm = false;
    handleSave = restartableTask(async (event: SubmitEvent)=>{
        this.errorMessage = null;
        try {
            if (event.target instanceof HTMLFormElement) {
                const data = new FormData(event.target);
                const rideIdValue = data.get('rideId') as string;
                if (rideIdValue.trim() === '') {
                    this.errorMessage = 'Ride ID cannot be blank';
                    return;
                }
                const rideId = Number(rideIdValue);
                if (isNaN(rideId)) {
                    this.errorMessage = 'Ride ID must be a number';
                    return;
                }
                const rideRequest = this.args.rideRequest;
                rideRequest.rideId = rideId;
                rideRequest.requestStatus = 'open';
                await rideRequest.save();
                this.args.onSaved?.();
            }
        } catch (ex) {
            this.args.rideRequest.rollbackAttributes();
            this.errorMessage = extractErrorMessage(ex, 'Something went wrong trying to save the Ride ID.');
        }
    });
    @action
    requestSaved() {
        this.showLinkRideForm = false;
        this.args.onSaved?.();
    }
    static{
        template_89c7478d8ad74de68e355b5c86e6677c(`
    <div>
      <LinkRideForm
        @isOpen={{this.showLinkRideForm}}
        @onCancel={{set this 'showLinkRideForm' false}}
        @onSaved={{this.requestSaved}}
        @rideRequest={{@rideRequest}}
      />

      <div>
        {{#if this.showRidePrompt}}
          {{#if this.errorMessage}}
            <UiAlert
              data-test-id='error-message'
              @variant='danger'
              class='mb-6'
            >
              {{this.errorMessage}}
            </UiAlert>
          {{/if}}

          <UiForm
            class='flex items-center gap-2'
            @noStyles={{true}}
            @onSubmit={{perform this.handleSave}}
            as |Form|
          >
            <Form.Group
              data-test-id='ride-id'
              class='flex items-center gap-2'
              as |Group|
            >
              <Group.Label class='flex-shrink-0'>Ride ID:</Group.Label>
              <Group.Input name='rideId' type='number' autocomplete='off' />
            </Form.Group>
            <Form.Actions as |Actions|>
              <UiButton
                data-test-id='cancel-btn'
                @onClick={{set this 'showRidePrompt' false}}
              >
                Cancel
              </UiButton>
              <Actions.Submit>
                Save
              </Actions.Submit>
            </Form.Actions>
          </UiForm>
        {{else}}
          <UiButtonSet>
            {{#if @rideRequest.hasMatchingRides}}
              <UiButton
                data-test-id='link-ride-btn'
                @onClick={{set this 'showLinkRideForm' true}}
              >
                Link to Onward Ride
              </UiButton>
            {{/if}}
            <UiButton
              data-test-id='associate-btn'
              @onClick={{set this 'showRidePrompt' true}}
            >
              Enter Onward Ride ID
            </UiButton>
          </UiButtonSet>
        {{/if}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'VaTools::Request::RideRequestForm': typeof RideRequestFormComponent;
    }
}
