import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type FeatureService from '../services/feature';
import type SessionService from '../services/onward-session';

export default class AuthenticatedRoute extends Route {
  @service declare feature: FeatureService;
  @service('onward-session') declare session: SessionService;
  @service declare router: RouterService;

  async beforeModel(transition: Transition) {
    this.session.requireAuthentication(transition, 'sign-in');

    if (this.session.isAuthenticated) {
      // If going to the root authenticated route, redirect to the default module.
      if (transition.to?.name === 'authenticated.index') {
        return this.router.replaceWith('authenticated.reporting.index');
      }

      await this.session.loadUser.perform();
      await this.feature.load();
    }
  }
}
