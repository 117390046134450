import { template as template_e5f527e9fa91466bbd70279c29e1a477 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type SessionService from '../../services/onward-session';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import { gt } from 'ember-truth-helpers';
import set from 'ember-set-helper/helpers/set';
import AccountFilterComponent from '../filters/account-filter';
import type { AccountFilter, AccountType } from '../filters/account-filter';
import BookTimeFilter from '../filters/book-time-filter';
import DistanceFilter from '../filters/distance-filter';
import FinancialResponsibilityFilter from '../filters/financial-responsibility-filter';
import type { FilterDateRange } from '../../utils/filters/date-range';
import LocationFilter from '../filters/location-filter';
import type { Location } from '../filters/location-filter';
import RequestedStartTimeFilter from '../filters/requested-start-time-filter';
import RideStatusFilter from '../filters/ride-status-filter';
import RideTypeFilter from '../filters/ride-type-filter';
import TransportPartnerFilter from '../filters/transport-partner-filter';
import TransportTypeFilter from '../filters/transport-type-filter';
import UiButton from '@onwardcare/ui-components/components/ui-button';
import UiForm from '@onwardcare/ui-components/components/ui-form';
import UiSlideover from '../ui-slideover';
export type Filters = {
    accountIds: string[];
    accountType: AccountType | null;
    bookTime: FilterDateRange | null;
    distanceIds: string[];
    financialResponsibilities: string[];
    geoId: string | null;
    regionIds: string[];
    requestedStartTime: FilterDateRange | null;
    rideCompletionStatuses: string[];
    rideTypeIds: string[];
    transportPartnerIds: string[];
    transportTypeIds: string[];
    zipcodeIds: string[];
};
export interface FiltersSignature {
    Element: HTMLDivElement;
    Args: {
        filters: Filters;
        onChange: (filters: Filters) => void;
    };
    Blocks: EmptyObject;
}
export default class FiltersComponent extends Component<FiltersSignature> {
    @service
    router: RouterService;
    @service('onward-session')
    session: SessionService;
    @tracked
    isOpen = false;
    @tracked
    accountFilter: AccountFilter = {
        accountIds: [],
        accountType: null
    };
    @tracked
    previousAccountFilter: AccountFilter = {
        accountIds: [],
        accountType: null
    };
    @tracked
    selectedBookTime: FilterDateRange | null = null;
    @tracked
    previousBookTime: FilterDateRange | null = null;
    @tracked
    selectedDistances: string[] = [];
    @tracked
    previousDistances: string[] = [];
    @tracked
    selectedFinancialResponsibilities: string[] = [];
    @tracked
    previousFinancialResponsibilities: string[] = [];
    @tracked
    selectedGeo: string | null = null;
    @tracked
    previousGeo: string | null = null;
    @tracked
    selectedRegions: string[] = [];
    @tracked
    previousRegions: string[] = [];
    @tracked
    selectedRequestedStartTime: FilterDateRange | null = null;
    @tracked
    previousRequestedStartTime: FilterDateRange | null = null;
    @tracked
    selectedRideStatuses: string[] = [];
    @tracked
    previousRideStatuses: string[] = [];
    @tracked
    selectedRideTypes: string[] = [];
    @tracked
    previousRideTypes: string[] = [];
    @tracked
    selectedTransportTypes: string[] = [];
    @tracked
    previousTransportTypes: string[] = [];
    @tracked
    selectedTransportPartners: string[] = [];
    @tracked
    previousTransportPartners: string[] = [];
    @tracked
    selectedZipcodes: string[] = [];
    @tracked
    previousZipcodes: string[] = [];
    get activeFilterCount() {
        let count = 0;
        if (this.selectedRequestedStartTime) {
            count++;
        }
        if (this.selectedBookTime) {
            count++;
        }
        if (this.accountFilter?.accountType) {
            count++;
        }
        if (this.accountFilter?.accountIds && this.accountFilter.accountIds.length > 0) {
            count++;
        }
        if (this.selectedFinancialResponsibilities.length > 0) {
            count++;
        }
        if (this.selectedTransportTypes.length > 0) {
            count++;
        }
        if (this.selectedTransportPartners.length > 0) {
            count++;
        }
        if (this.selectedRideStatuses.length > 0) {
            count++;
        }
        if (this.selectedRideTypes.length > 0) {
            count++;
        }
        if (this.selectedGeo) {
            count++;
        }
        if (this.selectedRegions.length > 0) {
            count++;
        }
        if (this.selectedZipcodes.length > 0) {
            count++;
        }
        if (this.selectedDistances.length > 0) {
            count++;
        }
        return count;
    }
    constructor(owner: unknown, args: FiltersSignature['Args']){
        super(owner, args);
        this.accountFilter = {
            accountIds: this.args.filters.accountIds,
            accountType: this.args.filters.accountType
        };
        this.previousAccountFilter = this.accountFilter;
        this.selectedBookTime = this.args.filters.bookTime;
        this.previousBookTime = this.selectedBookTime;
        this.selectedDistances = this.args.filters.distanceIds;
        this.previousDistances = this.selectedDistances;
        this.selectedFinancialResponsibilities = this.args.filters.financialResponsibilities;
        this.previousFinancialResponsibilities = this.selectedFinancialResponsibilities;
        this.selectedGeo = this.args.filters.geoId;
        this.previousGeo = this.selectedGeo;
        this.selectedRegions = this.args.filters.regionIds;
        this.previousRegions = this.selectedRegions;
        this.selectedRequestedStartTime = this.args.filters.requestedStartTime;
        this.previousRequestedStartTime = this.selectedRequestedStartTime;
        this.selectedRideStatuses = this.args.filters.rideCompletionStatuses;
        this.previousRideStatuses = this.selectedRideStatuses;
        this.selectedRideTypes = this.args.filters.rideTypeIds;
        this.previousRideTypes = this.selectedRideTypes;
        this.selectedTransportTypes = this.args.filters.transportTypeIds;
        this.previousTransportTypes = this.selectedTransportTypes;
        this.selectedTransportPartners = this.args.filters.transportPartnerIds;
        this.previousTransportPartners = this.selectedTransportPartners;
        this.selectedZipcodes = this.args.filters.zipcodeIds;
        this.previousZipcodes = this.selectedZipcodes;
    }
    @action
    openMenu() {
        this.previousAccountFilter = this.accountFilter;
        this.previousBookTime = this.selectedBookTime;
        this.previousDistances = this.selectedDistances;
        this.previousFinancialResponsibilities = this.selectedFinancialResponsibilities;
        this.previousGeo = this.selectedGeo;
        this.previousRegions = this.selectedRegions;
        this.previousRequestedStartTime = this.selectedRequestedStartTime;
        this.previousRideStatuses = this.selectedRideStatuses;
        this.previousRideTypes = this.selectedRideTypes;
        this.previousTransportPartners = this.selectedTransportPartners;
        this.previousTransportTypes = this.selectedTransportTypes;
        this.previousZipcodes = this.selectedZipcodes;
        this.isOpen = true;
    }
    @action
    closeMenu() {
        this.isOpen = false;
        this.accountFilter = this.previousAccountFilter;
        this.selectedBookTime = this.previousBookTime;
        this.selectedDistances = this.previousDistances;
        this.selectedFinancialResponsibilities = this.previousFinancialResponsibilities;
        this.selectedGeo = this.previousGeo;
        this.selectedRegions = this.previousRegions;
        this.selectedRequestedStartTime = this.previousRequestedStartTime;
        this.selectedRideStatuses = this.previousRideStatuses;
        this.selectedRideTypes = this.previousRideTypes;
        this.selectedTransportPartners = this.previousTransportPartners;
        this.selectedTransportTypes = this.previousTransportTypes;
        this.selectedZipcodes = this.previousZipcodes;
    }
    @action
    resetFilters() {
        this.accountFilter = {
            accountIds: [],
            accountType: null
        };
        this.selectedBookTime = null;
        this.selectedDistances = [];
        this.selectedFinancialResponsibilities = [];
        this.selectedGeo = null;
        this.selectedRegions = [];
        this.selectedRequestedStartTime = null;
        this.selectedRideStatuses = [];
        this.selectedRideTypes = [];
        this.selectedTransportPartners = [];
        this.selectedTransportTypes = [];
        this.selectedZipcodes = [];
    }
    @action
    locationSelected(location: Location) {
        this.selectedGeo = location.geoId;
        this.selectedRegions = location.regionIds;
        this.selectedZipcodes = location.zipcodeIds;
    }
    @action
    handleFilterChange() {
        this.isOpen = false;
        this.args.onChange?.({
            accountIds: this.accountFilter?.accountIds ?? [],
            accountType: this.accountFilter?.accountType ?? null,
            bookTime: this.selectedBookTime,
            distanceIds: this.selectedDistances,
            financialResponsibilities: this.selectedFinancialResponsibilities,
            geoId: this.selectedGeo,
            requestedStartTime: this.selectedRequestedStartTime,
            rideCompletionStatuses: this.selectedRideStatuses,
            rideTypeIds: this.selectedRideTypes,
            regionIds: this.selectedRegions,
            transportPartnerIds: this.selectedTransportPartners,
            transportTypeIds: this.selectedTransportTypes,
            zipcodeIds: this.selectedZipcodes
        });
    }
    static{
        template_e5f527e9fa91466bbd70279c29e1a477(`
    <UiButton
      data-test-id='filters-btn'
      @icon='funnel'
      @onClick={{this.openMenu}}
    >
      Filters

      {{#if (gt this.activeFilterCount 0)}}
        <span
          class='rounded-full bg-gray-200 px-1.5 text-xs font-semibold text-gray-900'
        >
          {{this.activeFilterCount}}
        </span>
      {{/if}}
    </UiButton>

    <UiSlideover @isOpen={{this.isOpen}} @onClose={{this.closeMenu}}>
      <div class='w-80 sm:w-96 md:w-[40rem] lg:w-[48rem]'>
        <div>
          <div class='flex justify-between align-bottom'>
            <h3 class='text-lg'>Filters</h3>

            {{#if (gt this.activeFilterCount 0)}}
              <UiButton
                data-test-id='filters-reset-button'
                @size='mini'
                @onClick={{this.resetFilters}}
              >
                Reset to Default
              </UiButton>
            {{/if}}
          </div>
          <hr class='mt-1' />
        </div>

        <div class='mt-8'>
          <UiForm
            @noStyles={{true}}
            @onSubmit={{this.handleFilterChange}}
            as |Form|
          >
            <div class='grid grid-cols-1 gap-6 md:grid-cols-2'>
              <RequestedStartTimeFilter
                @form={{Form}}
                @dateRange={{this.selectedRequestedStartTime}}
                @onChange={{set this 'selectedRequestedStartTime'}}
              />

              <BookTimeFilter
                @form={{Form}}
                @dateRange={{this.selectedBookTime}}
                @onChange={{set this 'selectedBookTime'}}
              />

              <AccountFilterComponent
                @form={{Form}}
                @accountFilter={{this.accountFilter}}
                @onChange={{set this 'accountFilter'}}
              />

              <TransportTypeFilter
                @form={{Form}}
                @transportTypeIds={{this.selectedTransportTypes}}
                @onChange={{set this 'selectedTransportTypes'}}
              />

              <TransportPartnerFilter
                @form={{Form}}
                @transportPartnerIds={{this.selectedTransportPartners}}
                @onChange={{set this 'selectedTransportPartners'}}
              />

              <RideStatusFilter
                @form={{Form}}
                @rideStatusIds={{this.selectedRideStatuses}}
                @onChange={{set this 'selectedRideStatuses'}}
              />

              <RideTypeFilter
                @form={{Form}}
                @rideTypeIds={{this.selectedRideTypes}}
                @onChange={{set this 'selectedRideTypes'}}
              />

              <FinancialResponsibilityFilter
                @form={{Form}}
                @financialResponsibilities={{this.selectedFinancialResponsibilities}}
                @onChange={{set this 'selectedFinancialResponsibilities'}}
              />

              <LocationFilter
                @form={{Form}}
                @geoId={{this.selectedGeo}}
                @regionIds={{this.selectedRegions}}
                @zipcodeIds={{this.selectedZipcodes}}
                @onChange={{this.locationSelected}}
              />

              <DistanceFilter
                @form={{Form}}
                @distanceIds={{this.selectedDistances}}
                @onChange={{set this 'selectedDistances'}}
              />
            </div>

            <Form.Actions class='mt-6' as |Actions|>
              <Actions.Submit data-test-id='filters-apply-btn' @full={{true}}>
                Apply
              </Actions.Submit>
            </Form.Actions>
          </UiForm>
        </div>
      </div>
    </UiSlideover>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Rides::Filters': typeof FiltersComponent;
    }
}
