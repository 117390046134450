import { template as template_c460bd3219f54517944e1ea2245e7e77 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import { service } from '@ember/service';
import { findRecord } from 'ember-data-resources';
import { eq } from 'ember-truth-helpers';
import type ReportingFiltersService from '../../services/reporting-filters';
import type SessionService from '../../services/onward-session';
import type ParentAccountModel from '../../models/parent-account';
import type AccountModel from '../../models/account';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
export interface FiltersSummarySignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
export default class FiltersSummaryComponent extends Component<FiltersSummarySignature> {
    @service
    reportingFilters: ReportingFiltersService;
    @service('onward-session')
    session: SessionService;
    get accountTypeFilter() {
        return this.reportingFilters.accountType;
    }
    get allAccountsSelected() {
        return (// If no accounts are selected.
        this.reportingFilters.filters.accountIds.length === 0 || // If all accounts are explicitely selected.
        this.reportingFilters.filters.accountIds.length === this.session.viewableAccounts.length);
    }
    get hasMultipleAccounts() {
        return (this.session.viewableAccounts.length > 1 || this.reportingFilters.accounts.length > 1);
    }
    get parentAccountId() {
        return this.reportingFilters.accountType === 'parent' ? this.reportingFilters.accounts[0] : undefined;
    }
    get b2bAccountId() {
        return this.reportingFilters.accountType === 'selected' && this.reportingFilters.accounts.length === 1 ? this.reportingFilters.accounts[0] : undefined;
    }
    parentAccount = findRecord<ParentAccountModel>(this, 'parent-account', ()=>this.parentAccountId);
    b2bAccount = findRecord<AccountModel>(this, 'account', ()=>this.b2bAccountId);
    static{
        template_c460bd3219f54517944e1ea2245e7e77(`
    <div class='mt-6 flex flex-col gap-2 sm:mt-8 lg:mt-10'>
      <h2
        data-test-id='filters-date-summary'
        class='flex flex-col text-lg text-gray-700 sm:flex-row sm:items-center sm:gap-2'
      >
        <UiIcon @icon='calendar' @type='mini' class='hidden sm:inline-block' />
        <span class='font-semibold'>{{this.reportingFilters.dateRange.label}}
          by
          {{this.reportingFilters.dateGroupingDisplay}}
        </span>
        <span>
          ({{this.reportingFilters.dateRange.description}})
        </span>
      </h2>

      <h2
        data-test-id='filters-account-summary'
        class='flex flex-col text-lg text-gray-700 sm:flex-row sm:items-center sm:gap-2'
      >
        <UiIcon
          @icon='building-office'
          @type='mini'
          class='hidden sm:inline-block'
        />
        {{#if this.session.isOnwardAssociate}}
          {{#if (eq this.accountTypeFilter 'b2b')}}
            All B2B Accounts
          {{else if (eq this.accountTypeFilter 'b2c')}}
            All B2C Accounts (includes B2C and B2B2C)
          {{else if (eq this.accountTypeFilter 'parent')}}
            <span>
              <span class='font-semibold'>
                All Accounts
              </span>
              for
              {{this.parentAccount.record.name}}
            </span>
          {{else if (eq this.accountTypeFilter 'selected')}}
            {{#if this.hasMultipleAccounts}}
              <span>
                <span class='font-semibold'>
                  {{this.reportingFilters.accounts.length}}
                </span>
                B2B Accounts
              </span>
            {{else}}
              {{this.b2bAccount.record.name}}
            {{/if}}
          {{else}}
            All Accounts
          {{/if}}
        {{else}}
          {{#if this.hasMultipleAccounts}}
            <span>
              <span class='font-semibold'>
                {{if this.allAccountsSelected 'All' 'Some'}}
                Accounts
              </span>
              for
              {{this.session.user.parentAccountName}}
            </span>
          {{else}}
            {{#if this.session.user.parentAccountName}}
              {{this.session.user.parentAccountName}}
            {{else}}
              {{this.session.user.accountName}}
            {{/if}}
          {{/if}}
        {{/if}}
      </h2>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::FiltersSummary': typeof FiltersSummaryComponent;
    }
}
