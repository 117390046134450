import { template as template_de51954d358349b28aeaa4392ef998d4 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import { all, restartableTask } from 'ember-concurrency';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - We need to figure out how to create the types for this.
import sortBy from 'ember-composable-helpers/helpers/sort-by';
import type UiFormGroupComponent from '@onwardcare/ui-components/components/ui-form/group';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
import type AccountModel from '../../models/account';
import type ParentAccountModel from '../../models/parent-account';
import type SessionService from '../../services/onward-session';
export interface AccountTypeOption {
    id: AccountType;
    name: string;
}
export type AccountType = 'b2b' | 'b2c' | 'parent' | 'selected';
export interface AccountFilter {
    accountType: AccountType | null;
    accountIds: string[];
}
export interface AccountFilterSignature {
    Element: HTMLDivElement;
    Args: {
        // TODO: Need to figure out how to properly use the `UiForm` block types.
        form: {
            Group: typeof UiFormGroupComponent;
        };
        accountFilter: AccountFilter;
        onChange: (value: AccountFilter) => void;
    };
    Blocks: EmptyObject;
}
export default class AccountFilterComponent extends Component<AccountFilterSignature> {
    @service('onward-session')
    session: SessionService;
    @service
    store: Store;
    @tracked
    b2bAccounts: AccountModel[] = [];
    @tracked
    parentAccounts: ParentAccountModel[] = [];
    accountTypes: AccountTypeOption[] = [
        {
            id: 'b2b',
            name: 'B2B'
        },
        {
            id: 'b2c',
            name: 'B2C'
        },
        {
            id: 'parent',
            name: 'Parent Account'
        },
        {
            id: 'selected',
            name: 'Selected B2B Accounts'
        }
    ];
    get accountsDisplay() {
        const { accountType, accountIds } = this.args.accountFilter;
        if (accountType === 'selected') {
            const accounts = this.b2bAccounts.filter((account)=>accountIds.includes(account.id));
            return accounts ? accounts.map((account)=>account.name).join(', ') : null;
        } else {
            const accounts = this.session.viewableAccounts.filter((account)=>accountIds.includes(account.id));
            return accounts ? accounts.map((account)=>account.name).join(', ') : null;
        }
    }
    get selectedAccountType() {
        const accountTypeId = this.args.accountFilter.accountType;
        if (accountTypeId) {
            const accountType = this.accountTypes.find((accountType)=>accountType.id === accountTypeId);
            if (accountType) {
                return accountType;
            }
        }
        return null;
    }
    get selectedParentAccount() {
        if (this.args.accountFilter.accountType !== 'parent') {
            return null;
        }
        const parentAccountId = this.args.accountFilter.accountIds[0];
        if (parentAccountId) {
            const parentAccount = this.parentAccounts.find((parentAccount)=>parentAccount.id === parentAccountId);
            if (parentAccount) {
                return parentAccount;
            }
        }
        return null;
    }
    get showParentAccounts() {
        return this.args.accountFilter.accountType === 'parent';
    }
    get showAccounts() {
        return (this.args.accountFilter.accountType === 'selected' || (this.session.isOnwardAssociate === false && this.session.viewableAccounts.length > 1));
    }
    get accounts() {
        return this.args.accountFilter.accountType === 'selected' ? this.b2bAccounts : this.session.viewableAccounts;
    }
    get groupingClasses() {
        const { accountType } = this.args.accountFilter;
        return `border-l-4 ${accountType === 'parent' || accountType === 'selected' ? 'border-purple-500' : 'border-transparent'} pl-3 -ml-4`;
    }
    constructor(owner: unknown, args: AccountFilterSignature['Args']){
        super(owner, args);
        if (this.session.isOnwardAssociate) {
            all([
                this.fetchParentAccounts.perform(),
                this.fetchB2bAccounts.perform()
            ]);
        }
    }
    fetchParentAccounts = restartableTask(async ()=>{
        const parentAccounts = await this.store.findAll('parent-account', {
            // Needed so that it doesn't just fetch the single record when reloading
            // the page.
            reload: true
        });
        this.parentAccounts = parentAccounts.slice();
    });
    fetchB2bAccounts = restartableTask(async ()=>{
        const b2bAccounts = await this.store.query('account', {
            filter: {
                channel: 'b2b'
            },
            // This is a hack to get all the accounts since we recently added
            // pagination to the account controller. We need to update the account
            // controller so that it bypasses pagination if we pass
            // `paginate_response=false`. This should only be available for admins.
            page: {
                size: 1000
            }
        });
        this.b2bAccounts = b2bAccounts.slice();
    });
    @action
    async accountTypeSelected(accountType: AccountTypeOption | null) {
        const accountFilter: AccountFilter = {
            accountType: accountType ? accountType.id : null,
            accountIds: []
        };
        if (accountType?.id === 'parent') {
            if (this.parentAccounts[0]) {
                // Default to the first parent account.
                accountFilter.accountIds = [
                    this.parentAccounts[0].id
                ];
            }
        } else if (accountType?.id === 'selected') {
            if (this.b2bAccounts[0]) {
                // Default to the first B2B account.
                accountFilter.accountIds = [
                    this.b2bAccounts[0].id
                ];
            }
        }
        this.args.onChange(accountFilter);
    }
    @action
    parentAccountSelected(parentAccount: ParentAccountModel | null) {
        const accountFilter: AccountFilter = {
            ...this.args.accountFilter,
            accountIds: parentAccount ? [
                parentAccount.id
            ] : []
        };
        this.args.onChange(accountFilter);
    }
    @action
    accountSelected(accounts: string[] | null) {
        if (accounts?.length === 0 && this.b2bAccounts[0]) {
            accounts = [
                this.b2bAccounts[0].id
            ];
        }
        const accountFilter: AccountFilter = {
            ...this.args.accountFilter,
            accountIds: accounts ?? []
        };
        this.args.onChange(accountFilter);
    }
    static{
        template_de51954d358349b28aeaa4392ef998d4(`
    <div class='flex flex-col gap-2 transition {{this.groupingClasses}}'>
      {{#if this.session.isOnwardAssociate}}
        <@form.Group data-test-id='account-type' as |Group|>
          <Group.Label class='flex items-center gap-1'>
            Account Type
            <UiIcon @icon='bolt' @type='micro' class='text-gray-400' />
          </Group.Label>
          <Group.Listbox
            @allowClear={{true}}
            @value={{this.selectedAccountType}}
            @onChange={{this.accountTypeSelected}}
            as |listbox|
          >
            <listbox.Button @placeholder='All Account Types'>
              {{this.selectedAccountType.name}}
            </listbox.Button>

            {{#each this.accountTypes as |accountType|}}
              <listbox.Option @value={{accountType}}>
                {{accountType.name}}
              </listbox.Option>
            {{/each}}
          </Group.Listbox>
        </@form.Group>
      {{/if}}

      {{#if this.showParentAccounts}}
        <@form.Group data-test-id='parent-account' as |Group|>
          <Group.Label class='flex items-center gap-1'>
            Parent Account
            <UiIcon @icon='bolt' @type='micro' class='text-gray-400' />
          </Group.Label>
          <Group.Listbox
            @value={{this.selectedParentAccount}}
            @onChange={{this.parentAccountSelected}}
            as |listbox|
          >
            <listbox.Button @placeholder='All Parent Accounts'>
              {{this.selectedParentAccount.name}}
            </listbox.Button>

            {{#each (sortBy 'name' this.parentAccounts) as |parentAccount|}}
              <listbox.Option @value={{parentAccount}}>
                {{parentAccount.name}}
              </listbox.Option>
            {{else}}
              <listbox.Empty>
                There are no Parent Accounts.
              </listbox.Empty>
            {{/each}}
          </Group.Listbox>
        </@form.Group>
      {{/if}}

      {{#if this.showAccounts}}
        <@form.Group data-test-id='accounts' as |Group|>
          <Group.Label class='flex items-center gap-1'>
            Accounts
            {{#if this.session.isOnwardAssociate}}
              <UiIcon @icon='bolt' @type='micro' class='text-gray-400' />
            {{/if}}
          </Group.Label>
          <Group.Listbox
            @multiple={{true}}
            @value={{@accountFilter.accountIds}}
            @onChange={{this.accountSelected}}
            as |listbox|
          >
            <listbox.Button @placeholder='All Accounts'>
              {{this.accountsDisplay}}
            </listbox.Button>

            {{#each (sortBy 'name' this.accounts) as |account|}}
              <listbox.Option @value={{account.id}}>
                {{account.name}}
              </listbox.Option>
            {{else}}
              <listbox.Empty>
                There are no Accounts.
              </listbox.Empty>
            {{/each}}
          </Group.Listbox>
        </@form.Group>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Filters::AccountFilter': typeof AccountFilterComponent;
    }
}
