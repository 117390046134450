import { template as template_5b10bf4ea0fc4c1694c92083e8b8e102 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { formatNumber } from '../../utils/number-format';
import InfoButton from '../info-button';
export interface StatSignature {
    Element: HTMLDivElement;
    Args: {
        label: string;
        tooltipText?: string;
        value: string | number;
    };
    Blocks: {
        default: [];
    };
}
export default class StatComponent extends Component<StatSignature> {
    get formattedValue(): string {
        if (typeof this.args.value === 'number') {
            return formatNumber(this.args.value) ?? '';
        }
        return this.args.value;
    }
    static{
        template_5b10bf4ea0fc4c1694c92083e8b8e102(`
    <div
      class='flex flex-wrap items-baseline bg-white px-4 py-6 sm:px-6 xl:px-8'
    >
      <dt class='text-base font-medium leading-6 text-gray-500'>
        {{@label}}

        {{#if @tooltipText}}
          <InfoButton class='text-gray-500'>
            {{@tooltipText}}
          </InfoButton>
        {{/if}}
      </dt>
      <dd
        class='w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900'
      >
        {{this.formattedValue}}
      </dd>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'ui-stats/stat': typeof StatComponent;
        'UiStats::Stat': typeof StatComponent;
    }
}
