import { template as template_e536776e227a4fd381d9558f93ac91b0 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import type TransportTypeModel from '../models/transport-type';
import UiBadge, { type UiBadgeColor, type UiBadgeSize } from '@onwardcare/ui-components/components/ui-badge';
export interface TransportTypeSignature {
    Element: HTMLSpanElement;
    Args: {
        size?: UiBadgeSize;
        textOnly?: boolean;
        transportType: TransportTypeModel;
    };
    Blocks: EmptyObject;
}
export default class TransportTypeComponent extends Component<TransportTypeSignature> {
    get color(): UiBadgeColor {
        switch(this.args.transportType?.name){
            case 'companion':
                return 'purple';
            case 'gurney':
                return 'blue';
            case 'rideshare':
                return 'green';
            case 'wheelchair':
                return 'yellow';
            case 'als_ambulance':
            case 'bls_ambulance':
            case 'cct_ambulance':
                return 'red';
            default:
                return 'gray';
        }
    }
    get text() {
        return this.args.transportType?.fullName ?? 'Unknown';
    }
    static{
        template_e536776e227a4fd381d9558f93ac91b0(`
    {{#if @textOnly}}
      <span data-test-id='transport-type' ...attributes>{{this.text}}</span>
    {{else}}
      <UiBadge
        data-test-id='transport-type'
        @color={{this.color}}
        @size={{@size}}
        @type='dot'
        ...attributes
      >
        {{this.text}}
      </UiBadge>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        TransportType: typeof TransportTypeComponent;
    }
}
