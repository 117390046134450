import { template as template_b2834e5bb4814b96851814c66f97a2b4 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import type { WithBoundArgs } from '@glint/template';
import { hash } from '@ember/helper';
import EmptyComponent from './ui-stats/empty';
import StatComponent from './ui-stats/stat';
export interface UiStatsSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: {
        default: [{
                Stat: WithBoundArgs<typeof StatComponent, never>;
                Empty: WithBoundArgs<typeof EmptyComponent, never>;
            }];
    };
}
const UiStatsComponent: TOC<UiStatsSignature> = template_b2834e5bb4814b96851814c66f97a2b4(`
  <div class='overflow-hidden rounded-lg border border-gray-300'>
    <dl class='mx-auto grid grid-cols-2 gap-px bg-gray-300 md:grid-cols-4'>
      {{yield
        (hash Empty=(component EmptyComponent) Stat=(component StatComponent))
      }}
    </dl>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UiStatsComponent;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        UiStats: typeof UiStatsComponent;
    }
}
