import { template as template_38f205e149414d658fd4f72985d83f42 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import type LocationModel from '../../models/location';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
const LocationComponent: TOC<{
    Element: HTMLDivElement;
    Args: {
        instructions?: string | null;
        location: LocationModel;
        roomNumber?: string | null;
    };
    Blocks: EmptyObject;
}> = template_38f205e149414d658fd4f72985d83f42(`
  <div class='flex items-center gap-2' ...attributes>
    <UiIcon @icon='map-pin' @type='solid' class='shrink-0 text-purple-800' />
    <div>
      {{#if @location.name}}
        <div data-test-id='location-name'>
          {{@location.name}}
        </div>
      {{/if}}

      <div data-test-id='location-address' class='text-sm text-gray-500'>
        {{@location.address}}
      </div>

      {{#if @roomNumber}}
        <div data-test-id='location-room-number' class='text-sm text-gray-500'>
          <span class='font-medium'>Room:</span>
          {{@roomNumber}}
        </div>
      {{/if}}

      {{#if @instructions}}
        <div
          data-test-id='location-instructions'
          class='text-xs italic text-gray-500'
        >
          {{@instructions}}
        </div>
      {{/if}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LocationComponent;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Rides::Location': typeof LocationComponent;
    }
}
