import { template as template_f4340285b9b24df893b82b3d161d0e16 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import Stat from './stat';
export interface EmptySignature {
    Element: HTMLDivElement;
    Args: {
        count: number;
    };
    Blocks: {
        default: [];
    };
}
export default class EmptyComponent extends Component<EmptySignature> {
    get items() {
        return Array.from({
            length: this.args.count
        }).map(()=>'--');
    }
    static{
        template_f4340285b9b24df893b82b3d161d0e16(`
    {{#each this.items as |item|}}
      <Stat @label={{item}} @value={{item}} />
    {{/each}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'ui-stats/empty': typeof EmptyComponent;
    }
}
