import { template as template_0848ae085722437da5aa3955d0721841 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { EmptyObject } from '@ember/component/helper';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type ArrayProxy from '@ember/array/proxy';
import set from 'ember-set-helper/helpers/set';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - We need to figure out how to create the types for this.
import sortBy from 'ember-composable-helpers/helpers/sort-by';
import { notEq } from 'ember-truth-helpers';
import { restartableTask } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import type VectorcareZone from 'onward-portal/models/vectorcare-zone';
import { extractErrorMessage } from 'onward-portal/utils/error-handlers';
import UiAlert from '@onwardcare/ui-components/components/ui-alert';
import UiButton from '@onwardcare/ui-components/components/ui-button';
import UiConfirmation from '../ui-confirmation';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
import UiMenu from '../ui-menu';
import UiTable from '../ui-table';
import ZonesForm from './zones-form';
export interface ZonesGridSignature {
    Element: HTMLDivElement;
    Args: {
        zones: ArrayProxy<VectorcareZone>;
    };
    Blocks: EmptyObject;
}
export default class ZonesGridComponent extends Component<ZonesGridSignature> {
    @service
    store: Store;
    @tracked
    deleteErrorMessage: string | null = null;
    @tracked
    zoneToDelete: VectorcareZone | null = null;
    @tracked
    zoneToEdit: VectorcareZone | null = null;
    deleteZone = restartableTask(async ()=>{
        this.deleteErrorMessage = null;
        if (this.zoneToDelete === null) {
            return;
        }
        try {
            await this.zoneToDelete.destroyRecord();
        } catch (ex) {
            this.zoneToDelete.rollbackAttributes();
            this.deleteErrorMessage = extractErrorMessage(ex, 'Something went wrong trying to delete the zone.');
        }
        this.zoneToDelete = null;
    });
    @action
    async cancel() {
        this.zoneToEdit = null;
    }
    @action
    newZone() {
        this.zoneToEdit = this.store.createRecord('vectorcare-zone');
    }
    static{
        template_0848ae085722437da5aa3955d0721841(`
    <div ...attributes>
      {{#if this.deleteErrorMessage}}
        <UiAlert data-test-id='error-message' @variant='danger' class='mb-4'>
          {{this.deleteErrorMessage}}
        </UiAlert>
      {{/if}}

      <div class='flex justify-end'>
        <UiButton
          data-test-id='add-zone-btn'
          @icon='plus-circle'
          @onClick={{this.newZone}}
        >
          Add Zone
        </UiButton>
      </div>

      <UiTable class='mt-4' as |Table|>
        <Table.Head as |Head|>
          <Head.Th>
            Zone
          </Head.Th>
          <Head.Th class='w-24'>
            Enabled
          </Head.Th>
          <Head.Th class='w-24'>
            <span class='sr-only'>Actions</span>
          </Head.Th>
        </Table.Head>
        <Table.Body as |Body|>
          {{#each (sortBy 'name' @zones) as |zone|}}
            <Body.Tr data-test-id='zone-row' as |Row|>
              <Row.Td data-test-id='name' @accent={{true}}>
                {{zone.name}}
              </Row.Td>
              <Row.Td data-test-id='enabled'>
                {{if zone.enabled 'Yes' 'No'}}
              </Row.Td>
              <Row.Td @align='right'>
                <div class='flex justify-end'>
                  <UiMenu as |menu|>
                    <menu.Trigger
                      data-test-id='grid-actions'
                      class='-ml-px flex h-6 rounded-md bg-white px-2 py-1 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 transition hover:bg-gray-50 focus:outline-purple-700'
                    >
                      <UiIcon @icon='ellipsis-horizontal' @type='micro' />
                      <UiIcon @icon='chevron-down' @type='micro' />
                      <span class='sr-only'>Open menu</span>
                    </menu.Trigger>

                    <menu.Button
                      data-test-id='edit-btn'
                      @onClick={{set this 'zoneToEdit' zone}}
                    >
                      Edit
                    </menu.Button>
                    <menu.Button
                      data-test-id='delete-btn'
                      @onClick={{set this 'zoneToDelete' zone}}
                    >
                      Delete
                    </menu.Button>
                  </UiMenu>
                </div>
              </Row.Td>
            </Body.Tr>
          {{else}}
            <Body.Empty>
              There are no zones.
            </Body.Empty>
          {{/each}}
        </Table.Body>
      </UiTable>

      <ZonesForm
        @zone={{this.zoneToEdit}}
        @onCancel={{this.cancel}}
        @onSave={{this.cancel}}
      />

      <UiConfirmation
        @isOpen={{notEq this.zoneToDelete null}}
        @onCancel={{set this 'zoneToDelete' null}}
        @onConfirm={{perform this.deleteZone}}
      >
        <:title>Delete Zone?</:title>
        <:body>
          <p>
            Do you really want to delete the zone "{{this.zoneToDelete.name}}"?
          </p>
        </:body>
      </UiConfirmation>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Vectorcare::ZonesGrid': typeof ZonesGridComponent;
    }
}
