import { template as template_a042a7a88c634eaa84e2dbc158f916a1 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import BasicColumnChart from '../basic-column-chart';
export interface DriverReviewCountsSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const DriverReviewCounts: TOC<DriverReviewCountsSignature> = template_a042a7a88c634eaa84e2dbc158f916a1(`
  <BasicColumnChart
    @format='percent'
    @modelName='driver-review-percentage'
    @title='Trip Reviews of Drivers'
    @tooltipText='Percent of trips where Riders provided a star rating of 1-5 and overall average for the date range.'
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DriverReviewCounts;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::DriverReviewCounts': typeof DriverReviewCounts;
    }
}
