import { template as template_4f6646fae6634b95b3adf27fca888668 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import { or } from 'ember-truth-helpers';
import type DriverModel from '../../models/driver';
import SectionHeader from './section-header';
import UiAvatar from '../ui-avatar';
import UserLanguages from '../user-languages';
export interface SearchSignature {
    Element: HTMLDivElement;
    Args: {
        driver: DriverModel | null;
    };
    Blocks: EmptyObject;
}
export default class DriverCardComponent extends Component<SearchSignature> {
    get hasDriver() {
        const { driver } = this.args;
        return driver?.id && driver.isPlaceholder === false;
    }
    static{
        template_4f6646fae6634b95b3adf27fca888668(`
    <div data-test-id='driver-card' class='flex flex-col gap-3'>
      <SectionHeader class='flex items-baseline gap-1'>
        Driver

        {{#if this.hasDriver}}
          <span data-test-id='driver-id' class='text-xs text-gray-600'>
            ({{@driver.id}})
          </span>
        {{/if}}
      </SectionHeader>

      {{#if this.hasDriver}}
        <div class='flex flex-wrap items-center justify-between gap-3'>
          <div class='flex items-center gap-2'>
            <UiAvatar @photoUrl={{@driver.profilePhotoUrl}} />

            <div>
              <div class='flex flex-wrap items-baseline gap-1'>
                <span class='inline-flex'>
                  <span data-test-id='driver-name' class='font-medium'>
                    {{@driver.displayName}}
                  </span>
                  {{if (or @driver.age @driver.gender) ','}}
                </span>

                {{#if (or @driver.age @driver.gender)}}
                  <span>
                    {{#if @driver.age}}
                      <span
                        data-test-id='driver-age'
                        class='group inline-flex text-sm font-normal text-gray-500'
                      >
                        {{@driver.age}}
                        <span class='group-last:hidden'>,</span>
                      </span>
                    {{/if}}

                    {{#if @driver.gender}}
                      <span
                        data-test-id='driver-gender'
                        class='group inline-flex text-sm font-normal text-gray-500'
                      >
                        {{@driver.gender}}
                        <span class='group-last:hidden'>,</span>
                      </span>
                    {{/if}}
                  </span>
                {{/if}}
              </div>
              {{#if @driver.transportPartner.id}}
                <div>
                  <span
                    data-test-id='driver-transport-partner'
                    class='text-sm text-gray-600'
                  >
                    {{@driver.transportPartner.name}}
                  </span>
                </div>
              {{/if}}
            </div>
          </div>

          <div>
            {{#let @driver.currentVehicle as |vehicle|}}
              <div class='text-sm'>
                <span data-test-id='vehicle-year'>
                  {{vehicle.year}}
                </span>
                <span data-test-id='vehicle-make'>
                  {{vehicle.make}}
                </span>
                <span data-test-id='vehicle-model'>
                  {{vehicle.model}}
                </span>
              </div>
              <div class='flex gap-1 text-xs italic text-gray-600'>
                <span data-test-id='vehicle-color'>
                  {{vehicle.color}},
                </span>
                <span data-test-id='vehicle-plate'>
                  {{vehicle.licensePlate}}
                </span>
              </div>
            {{/let}}
          </div>
        </div>

        <div class='mt-3 flex flex-col gap-2'>
          {{#if @driver.userLanguages}}
            <div class='flex gap-1 text-sm'>
              <span class='text-gray-600'>Languages:</span>
              <UserLanguages @userLanguages={{@driver.userLanguages}} />
            </div>
          {{/if}}

          {{#if @driver.veteranStatus.id}}
            <div class='flex gap-1 text-sm'>
              <span class='text-gray-600'>Veteran Status:</span>
              <span data-test-id='driver-veteran-status'>
                {{@driver.veteranStatus.displayName}}
              </span>
            </div>
          {{/if}}
        </div>
      {{else}}
        <span data-test-id='no-driver' class='text-sm italic text-gray-600'>
          Driver details will be provided closer to the ride start time.
        </span>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Rides::DriverCard': typeof DriverCardComponent;
    }
}
